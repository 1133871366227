import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'
import Container from 'components/Container'


const StyledLink = styled.div`
  padding-right: 25px;
  font-size: 22px;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colors.highlight};
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: flex-end;
  height:100%;
  width: 100%;
  :after {
    content: ' ';
    position: absolute;
    height: 20px;
    width: 20px;
    border-color: ${({ theme }) => theme.colors.base.orange};
    border-style: solid;
    border-top-width: 2px;
    border-left: 0;
    border-bottom: 0;
    border-right-width: 2px;
    left: 100%;
    bottom: 0;
    transform: translate(-150%, -50%) rotate(45deg);
    transition: margin-left 0.3s ease-in-out;
  }
`

const LinkText = styled.div`
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
`

const LinkContainer = styled.div`
    flex-wrap: wrap;
    flex: 1;
    display: flex;
    padding: 12px;
    min-width: 260px;
    margin-bottom: 1rem;
    :hover {
    ${StyledLink}:after {
      margin-left: 1em;
    }
    ${LinkText} {
        opacity: 1;
    }
    }
`

const Links = styled.div`
    flex-wrap: wrap;
    display: flex;
    color: ${({ theme }) => theme.colors.highlight};
`

// text-transform: uppercase;
const Title = styled.h5`
    width: 100%;
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.base.black};
`

export default function StoryblokInteresting ({ data, ...props }) {
  const { links, label } = data

  return (
    <Container extraWide {...props}>
      <Title>{label}</Title>
      <Links>
        {links.map(link => (
          <LinkContainer>
            <StyledLink as={Link} link={link.link}>
              {link.label}
            </StyledLink>
            <LinkText>
              <RemarkRenderer ast={link.description?.childMarkdownRemark?.htmlAst} />
            </LinkText>
          </LinkContainer>
        ))}
      </Links>
    </Container>
  )
}
